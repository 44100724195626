const initialState = {
    items: [
        {
            title: 'How It Works?',
            href: '#'
        },
        {
            title: 'Product Features',
            href: '#features'
        },
        {
            title: 'Benefits',
            href: '#benefits'
        },
        {
            title: 'Testimonials',
            href: '#testimonials'
        },
        {
            title: 'FAQ',
            href: '#faq'
        },
        {
            title: 'Contact Us',
            href: '#contact'
        },
    ]
}

const navReducer = (state = initialState, action) => state;

export default navReducer;