// import placeholder from "../../assets/common/avatar-placeholder-generic.png";

const initialState = {
    features: [
        {
            title: "HIPAA COMPLIANT PATIENT MESSAGING\n",
            description: "Traditional phone communication is inefficient and frustrating for patients.  In today’s society most people are more inclined to text than to call.  However, traditional text messaging directly to a provider’s phone is not HIPAA compliant.  Pigeon provides a HIPAA compliant cross platform solution that does not require the patient to download an app or log into a portal.",
            image: require('../../assets/frontpage/features-1.svg').default,
        },
        {
            title: "Convenient Web App for Office Staff",
            description: "Increase efficiency of your office staff with our easy-to-use Pigeon Web Application. Pigeon will instantly deliver messages, images, lab results, private health documents, and patient forms. No need to log in to a portal. EMR integration automatically uploads all information to the patient’s chart.",
            image: require('../../assets/frontpage/features-2.svg').default,
        },
        {
            title: "Immediate Response with Mobile App",
            description: "Swiftly deal with all patients’ requests on the go with Pigeon Mobile App. Broadcast message to quickly communicate to all of your patients with one message or a subset of patients.\n" +
                "Mobile App makes it easy to send appointment notes and medication reminders with a few clicks in a couple of seconds.",
            image: require('../../assets/frontpage/features-3.svg').default,
        },
        {
            title: "Video Telehealth Platform",
            description: "Just like Pigeon text messaging, our Video Chat is seamlessly integrated, easy to use, and does not require an app install or portal log-in. All video chats with your patients automatically apply End-to-End Encryption to ensure HIPAA compliance for Privacy and Security.",
            image: require('../../assets/frontpage/features-4.svg').default,
        },
        {
            title: "AI Billing Assistant",
            description: "This artificial intelligence module will significantly reduce the staff time required to identify billable doctor to patient conversations within Pigeon, and will categorize each event accordingly with the proper billing code so that it could be submitted for reimbursement.  With tens of thousands of messages sent each month, this AI assistant will make an otherwise daunting task, possible.",
            image: require('../../assets/frontpage/features-5.svg').default,
        },
    ],
    benefits: [
        {
            title: "HIPAA Compliant",
            description: "No install required HIPAA compliant patient messaging & telehealth. Pigeon adheres to the Security Rule, Privacy Rule, and Breach Notification Rule.",
            image: require('../../assets/frontpage/benefit-1.svg').default,
        },
        {
            title: "Secure",
            description: "Pigeon uses industry-leading security standards to keep patient information safe. It utilizes AWS servers for data storage. All data is fully encrypted using AES-256, including messages, patient information, and PHI.",
            image: require('../../assets/frontpage/benefit-2.svg').default,
        },
        {
            title: "Integrated",
            description: "Pigeon integrates directly to your EMR system for seamless documentation of all patient communication. Pigeon logs all conversations with patients, so clinical documentation is a breeze.",
            image: require('../../assets/frontpage/benefit-3.svg').default,
        },
        {
            title: "Easy to Use",
            description: "Pigeon is super easy to use both for the patients and for the office staff. We carefully developed web and mobile apps’ interface to ensure a supreme user experience.",
            image: require('../../assets/frontpage/benefit-4.svg').default,
        },
        {
            title: "Cost-Effective",
            description: "Pigeon allows 85% time cost savings compared to traditional phone call rates. Our AI-Assisted Billing optimizes spends for each client based on their preferences and needs.",
            image: require('../../assets/frontpage/benefit-5.svg').default,
        },
        {
            title: "Patient-Oriented",
            description: "Patients feel more connected with their providers and the rate for patient re-admittance for minor issues decreases.",
            image: require('../../assets/frontpage/benefit-6.svg').default,
        },
    ],

    testimonials: [
        {
            name: "Catherine Bevan, M.D.",
            title: "FENOM Women’s Care",
            description: "We have loved having Pigeon in our office, and patients love it too! One of our main goals is to make communication between doctor and patient easier and stress-free. Pigeon has made this possible. I can’t recommend it enough.",
            image: require("../../assets/frontpage/testimonials/catherine_bevan.png").default,
        },
        {
            name: "Andrea Palmer, M.D.",
            title: "Texas Health Care",
            description: "Pigeon is a hit with our patients and my staff. The ease of text messaging has been a huge help on both sides of the patient/provider relationship, making patients not feel silly for asking little questions and it’s so easy to reply that it’s not a burden for my staff to respond quickly.",
            image: require("../../assets/frontpage/testimonials/andrea_palmer.png").default,
        },
        {
            name: "Lindsay Breedlove, M.D.",
            title: "Texas Health Care",
            description: "Pigeon has been an exceedingly positive change in our office with communication with our patients! It is very user-friendly and our patients love it! Highly recommend!",
            image: require("../../assets/frontpage/testimonials/lindsay_breedlove.png").default,
        },
    ]
}

const frontpageReducer = (state = initialState, action) => state;

export default frontpageReducer;